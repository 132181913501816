import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineRenewalOfPortArmsPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Rinnovo Porto D’armi'

  const description =
    'Per il rilascio o il rinnovo del porto d’armi, il certificato di idoneità psicofisica potrà essere rilasciato anche <b>dai singoli medici della Polizia di Stato, dei vigili del fuoco o da medici militari in servizio permanente e in attività di servizio.</b><br> I medici militari e della polizia tornano ad avere competenza per il rilascio dei certificati medici per il rilascio o il rinnovo del porto d’armi, <b>non soltanto all’interno delle loro strutture di competenza, bensì anche come professionisti individuali</b>. Diversamente dai certificati medici per la mera detenzione, però, <b>è richiesto che i medici siano in servizio attivo e in attività di servizio, cioè che non si trovino in quiescenza o congedo.</b><br><br> ING GROUP offre la possibilità di fruire dei servizi di svariati medici abilitati allo specifico servizio.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
